
.big-chief-pad {
  background-color: #fff8dc; /* Light yellowish paper color */
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 0;
  margin-left: 50;
  padding: 50;
  z-index: 1;
}

/* Creating ruled lines with pseudo-elements */
.big-chief-pad::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  height: 100%;
  background: repeating-linear-gradient(
      to bottom,
      transparent,
      transparent 25px,
      #ccc 25px,
      #ccc 26px
  );
  z-index: -1;
}

.big-chief-pad::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 50px;
  right: 50px;
  height: 100%;
  background: repeating-linear-gradient(
      to bottom,
      transparent,
      transparent 25px,
      #ccc 25px,
      #ccc 26px
  );
  z-index: -1;
}

.big-chief-pad  {
  padding: 10px;
  font-family: "LexieReadable";
  z-index: 2;
}

/* Additional styling for the content */
.big-chief-pad p {
  margin: 20px 50px;
  line-height: 25px;
  z-index: 3;
  position: relative;
}

.title {
  font-family: "LexieReadableBold"; /* Adjustment for the title's font */
  font-size: 10;
  margin-bottom: 20px;
}

.button-container {
  margin-top: 20px;
}