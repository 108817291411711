.steampunk-button {
    background-color: #b8860b; /* Dark golden background */
    color: #fff;
    font-size: 1.5rem;
    padding: 10px 20px;
    border: 2px solid #d2691e; /* Chocolate border */
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 3px 3px 8px #5f4c0b; 
    text-shadow: 1px 1px 2px #8b4513;
  }
  
  .steampunk-button:hover {
    background-color: #cd853f; /* Peru background on hover */
  }